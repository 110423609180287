.AboutPage {
    min-height: 98.1vh;
}

.interestContainer {
    display: flex;
    max-width: 70rem;
    margin: 0rem 1rem;
    margin-top: 5rem;
    margin-bottom: 1rem;
    padding-right: 2rem;
    flex-direction: row;
    border-radius: 0.8rem;
    background-color: #6e879c73;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.intImg {
    width: 18rem;
}

.interestMsg {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.interestMsgTitle {
    color: #24354ba8;
    font-size: 2rem;
    font-family: Righteous;
}

.interestMsgText {
    display: flex;
    text-align: justify;
    flex-direction: column;
    font-family: VarelaRound;
}

@media (max-width: 700px) {
    .interestContainer {
        padding: 0rem 2rem;
        align-items: center;
        flex-direction: column;
    }
}