.HomePage {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 98.1vh;
}

.HomePage-MainContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 8vh 2vh;
    gap: 1rem;
}

.HomePage-TitleContainer {
    gap: 0.5rem;
    display: flex;
    padding: 0.5rem;
    flex-wrap: wrap;
    align-items: flex-end;
    text-shadow: 4px 3px 10px rgba(90, 89, 89, 0.37);
}

.HomePage-Greetings {
    font-size: 2rem;
    font-family: Righteous;
}

.HomePage-UserName {
    font-size: 1.9rem;
    font-family: Righteous;
    color: rgb(85, 88, 90);
}

.HomePage-TableListMainContainer {
    height: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    border-width: 0.1rem;
    border-radius: 0.6rem;
    flex-direction: column;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.HomePage-AccessibilityContainer {
    width: 100%;
    display: flex;
    padding: 0.5rem;
    background-color: #91818A;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.HomePage-AccessibilityContainer1 {
    display: flex;
    width: min-content;
    flex-direction: row;
}

.HomePage-SearchBarContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.HomePage-MainButtonContainer {
    gap: 0.3rem;
    display: flex;
}

.HomePage-SearchBar {
    width: 15rem;
    font-size: 1.1rem;
    border-width: 0.1rem;
    padding: 0.2rem 2rem;
    padding-left: 1.6rem;
    border-radius: 0.3rem;
    font-family: VarelaRound;
}

.HomePage-SearchIcon {
    margin: -2rem;
    display: flex;
    font-size: 1.3rem;
}

.HomePage-Search-CancelButton {
    display: flex;
    border: solid;
    cursor: pointer;
    margin-left: -12rem;
    border-width: 0.1rem;
    border-color: black;
    border-radius: 0.3rem;
    color: rgb(184, 2, 2);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.HomePage-Search-CancelButton:active {
    transform: scale(0.95);
}

.HomePage-MainButton1 {
    height: 100%;
    display: flex;
    cursor: pointer;
    font-size: 1.5rem;
    align-items: center;
    border-width: 0.1rem;
    border-radius: 0.2rem;
    justify-content: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.HomePage-MainButton1:active {
    transform: scale(0.95);
}

.HomePage-TableListContainer {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0.5rem;
    border-top: solid;
    min-height: 20rem;
    border-width: 0.1rem;
    flex-direction: column;
    background-color: rgba(252, 252, 252, 0.678);
}

.HomePage-DataGrpContainer {
    display: flex;
    gap: 0.5rem;
    height: 2rem;
    width: 100%;
}

.HomePage-EditDataGrpInput {
    width: 100%;
    padding-left: 1rem;
    padding-right: 6.5rem;
    padding-top: 0.3rem;
    height: 100%;
    font-size: 1.1rem;
    font-family: VarelaRound;
    border-radius: 0.5rem;
    border-width: 0.1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.HomePage-EditDataGrpBtn, .HomePage-EditDataGrpBtnDelete {
    display: flex;
    cursor: pointer;
    font-size: 1.8rem;
    text-align: center;
    align-items: center;
    margin-left: -3.2rem;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    background-color: rgba(236, 157, 157, 0.432);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;}

.HomePage-EditDataGrpBtn{
    background-color: rgba(129, 219, 166, 0.432)
}

.HomePage-EditDataGrpBtnDelete {
    background-color: rgba(236, 157, 157, 0.432)
}

.HomePage-EditDataGrpBtn:active {
    transform: scale(0.95);
}

.HomePage-DataGrpContainer-IndividualTable {
    gap: 1.5rem;
    width: 100%;
    display: flex;
    cursor: pointer;
    min-height: 3rem;
    padding-left: 1rem;
    align-items: center;
    padding: 0.3rem 0rem;
    font-family: VarelaRound;

}

.HomePage-DataGrpContainer-IndividualTable:hover {
    transition: 0.5s;
    border-radius: 0.3rem;
    background-color: #FCFCFC;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.HomePage-AddDataGrpContainer-IndividualTable {
    gap: 0.3rem;
    display: flex;
    border-bottom: solid;
    border-width: 0.1rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0.8rem;
    flex-direction: column;
    border-color: #24354ba8;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 15px 10px -20px;
}

.HomePage-AddDataGrpContainer1-IndividualTable {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.HomePage-AddDataGrpBtn1, .HomePage-AddDataGrpBtn1Disable {
    display: flex;
    cursor: pointer;
    font-size: 1.5rem;
    align-items: center;
    border-radius: 0.5rem;
    border-width: 0.1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.HomePage-AddDataGrpBtn1 {
    background-color: rgba(129, 219, 166, 0.432);
} 

.HomePage-AddDataGrpBtn1Disable {
    background-color: #dddddd;
}

.HomePage-AddDataGrpBtn1:active {
    transform: scale(0.95);
}

.HomePage-IndividualTable-Name {
    border-left: solid;
    padding-left: 0.5rem;
    cursor: pointer;
}

.HomePage-AddDataGrpBtnContainer {
    gap: 0.2rem;
    display: flex;
    cursor: pointer;
    font-size: 1.8rem;
    text-align: center;
    align-items: center;
    margin-left: -6.2rem;
    border-radius: 0.5rem;
    background-color: white;
}

.HomePage-AddDataGrpBtn {
    display: flex;
    cursor: pointer;
    font-size: 1.8rem;
    text-align: center;
    height: min-content;
    align-items: center;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.HomePage-AddDataGrpBtn:active {
    transform: scale(0.95);
}

@media (max-width: 500px) {
    .HomePage-TableListMainContainer {
        margin: 0rem;
    }

    .HomePage-MainContainer {
        margin: 8vh 0vh;
    }

    .HomePage {
        width: 90%;
    }
}

@media (max-width:480px) {
    .HomePage-AccessibilityContainer1 {
        width: 100%;
        flex-direction: column;
    }

    .HomePage-AccessibilityContainer {
        flex-direction: column;
        align-items: flex-end;
        gap: 0.2rem;
    }

    .HomePage-SearchBar {
        width: 100%;
    }

    .HomePage-SearchBarContainer {
        width: 100%;
    }

    .HomePage-Search-CancelButton {
        margin-left: 2.6rem;
        background-color: ivory;
        font-size: 1.7rem;
    }
}