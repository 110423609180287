.ImageConverter {
    width: 100%;
    display: flex;
    min-height: 98.1vh;
}

.ImageConverterContainer {
    width: 100%;
    display: flex;
    padding: 1rem;
    margin-top: 3rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.ImageConverter-TitleContainer {
    display: flex;
    /* border: solid; */
    padding: 0.5rem 0rem;
    border-radius: 0.5rem;
    border-width: 0.15rem;
    justify-content: center;
    /* background-color: #D0DAE4; */
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.ImageConverter-Title {
    font-size: 2rem;
    color: #24354B;
    font-family: Righteous;
}

.ImageConverter-MainContainer {
    gap: 1rem;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.ImageConverter-ConverterContainer {
    display: flex;
    border: solid;
    border-width: 0.15rem;
    border-radius: 0.5rem;
    flex-direction: column;
    justify-content: space-evenly;
    /* background-color: #E7EDF2; */
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.ImageConverter-InputContainer {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 0rem;
    flex-direction: column;
}

.ImageConverter-ImageDragDropBox {
    width: 70%;
    height: 15rem;
    border: solid;
    display: flex;
    padding: 1.5rem;
    text-align: center;
    border-radius: 1rem;
    border-width: 0.1rem;
    border-style: dotted;
    align-content: center;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.ImageConverter-UploadIcon {
    /* border: solid; */
    display: flex;
    font-size: 2rem;
    justify-content: center;
}

.ImageConverter-UploadIconWrap{
    border-left: solid;
    border-right: solid;
    width: min-content;
    height: min-content;
    border-width: 0.1rem;
    padding: 0rem 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
}

.ImageConverter-InputOutputTitle {
    width: 100%;
    font-size: 1.5rem;
    /* border: solid; */
    font-family: VarelaRound;
}

.ImageConverter-SelectImage {
    border: solid;
    transition: 0.5s;
    font-size: 1.1rem;
    padding: 0.5rem 0rem;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.ImageConverter-SelectImage:active {
    transform: scale(0.95);
    transition: 0.5s;
}

.ImageConverter-OutputContainer {
    width: 100%;
    display: flex;
    padding: 0.5rem;
    border-top: solid;
    border-width: 0.1rem;
    padding-bottom: 1rem;
    flex-direction: column;
}

.ImageConverter-OutputTitleContainer {
    display: flex;
    justify-content: space-between;
}

.ImageConverter-ConvertContainer {
    /* border: solid; */
    display: flex;
    gap: 0.8rem;
    flex-direction: row;
}

.ImageConverter-MainSelectImgTypeConvertContainer {
    display:flex;
    gap: 0.5rem;
    align-items: center;
    flex-direction: row;
}

.ImageConverter-MainSelectImgTypeMSG {
    font-size: 1.1rem;
    white-space: nowrap;
}

.ImageConverter-ConvertAllBtn {
    gap: 1rem;
    display: flex;
    color: #24354B;
    align-items: center;
    white-space: nowrap;
    padding: 0rem 1.5rem;
    background-color: orange;
}

.ImageConverter-UploadedConvertLst {
    height: 80%;
    width: 8rem;
}

.ImageConverter-UploadedImgContainer {
    /* border:solid; */
    margin: 0.2rem 0.5rem;
    display: flex;
    flex-direction: row;
    padding: 0.2rem 0.5rem;
    justify-content: space-between;
    border-width: 0.1rem;
    border-radius: 0.3rem;
    background-color: #F3F7FA;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.ImageConverter-UploadedImgContainerSub{
    gap: 0.5rem;
    display: flex;
    flex-direction: row;
}

.ImageConverter-UploadedConvertType {
    gap: 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.ImageConverter-UploadedImgName {
    width: 15rem;
    /* border: solid; */
    overflow: hidden;
}

.ImageConverter-UploadedImgStatus {
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.ImageConverter-UploadedImgEdit {
    display: flex;
    border: solid;
    font-size: 1.5rem;
    cursor: pointer;
    border-width: 0.1rem;
    border-color: black;
    border-radius: 0.3rem;
    color: rgb(184, 2, 2);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

@media (max-width:850px) {
    .ImageConverter-UploadedImgContainer {
        gap: 0.2rem;
        flex-direction: column;
    }
    .ImageConverter-UploadedImgContainerSub {
        justify-content: space-between;
    }
}

@media (max-width: 750px) {
    .ImageConverter-OutputTitleContainer{
        flex-direction: column;
    }
    .ImageConverter-ConvertContainer {
        gap: 0.2rem;
        flex-direction: column;
    }
    .ImageConverter-UploadedConvertLst {
        height: 100%;
    }
    .ImageConverter-ConvertAllBtn {
        padding: 0.3rem;
        border-radius: 0.4rem;
        justify-content: center;
    }
}

@media (max-width:480px) {
    .ImageConverter-UploadedImgContainerSub {
        flex-direction: column;
    }

    .ImageConverter-UploadedImgName {
        width: 100%;
        background-color: whitesmoke;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .ImageConverter-UploadedImgStatus {
        width: 100%;
        background-color: whitesmoke;
    }
    .ImageConverter-UploadedConvertType{
        width: 100%;
    }
    .ImageConverter-UploadedConvertLst {
        width: 100%;
    }
    .ImageConverter-UploadedImgEdit {
        display: flex;
        justify-content: center;
    }
    .ImageConverter-MainContainer {
        width: 95%;
    }
}