.ToolPage-Title {
    font-size: 2rem;
    font-family: Righteous;
}

.ToolPage-TitleContainer {
    gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    text-align: center;
    padding: 1rem 0rem;
    justify-content: center;
    text-shadow: 4px 3px 10px rgba(90, 89, 89, 0.37);
}

.ToolPage-SearchBarContainer {
    display: flex;
    width: 18.5rem;
    align-items: center;
}

.ToolPage-AccessibilityContainer {
    width: 100%;
    display: flex;
    padding: 0.5rem;
    justify-content: flex-end;
    background-color: #24354ba8;
}

.ToolPage-SearchBar {
    width: 18rem;
    font-size: 1.1rem;
    padding-right: 2rem;
    border-width: 0.1rem;
    padding: 0.2rem 1rem;
    padding-left: 1.5rem;
    border-radius: 0.3rem;
    font-family: VarelaRound;
}

.ToolPage-Search-CancelButton {
    display: flex;
    border: solid;
    cursor: pointer;
    margin-left:-15rem;
    border-width: 0.1rem;
    border-color: black;
    border-radius: 0.3rem;
    color: rgb(184, 2, 2);
}

.ToolPage-ToolListContainer{
    gap: 2rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    align-items: flex-start;
    flex-direction: row;
    padding-bottom: 2rem;
    justify-content: space-evenly;
}

.ToolContainer {
    display: flex;
    max-width: 10rem;
    border-style: solid;
    border-radius: 1rem;
    padding-top: 0.5rem;
    align-items: center;
    border-width: 0.1rem;
    flex-direction: column;
    justify-content: center;
    border-color: rgba(0, 0, 0, 0.116);
    transition: 1s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.ToolContainer1 {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ToolInfoImg {
    border: solid;
    display: flex;
    font-size: 3rem;
    border-radius: 0.5rem;
    border-color: orange;
    background-color: orange;
}

.ToolInfoTitle {
    color: #24354B;
    padding: 0.2rem 0.3rem;
    font-size: 1.3rem;
    font-family: Viga;
    text-align: center;
    border-width: 0.15rem;
}

.ToolInfoContainer{
    float: left;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ToolInfoContainer2 {
    gap: 0.5rem;
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    flex-direction: column;
    justify-content:center;
}

.ServerStatusColour {
    display: flex;
    text-align: justify;
    align-items: center;
    color: red;
}

.ToolInfoButton {
    border: solid;
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
    font-family: VarelaRound;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    height: 100%;
    width: 90%;
    color: #24354B;
    text-decoration: none;
    transition: 1s;
    background-color: rgba(233, 233, 233, 0.788);
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.ToolInfoButton:active {
    transform: scale(0.95);
}

.ToolInfoButtonIA{
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    border: solid;
    border-radius: 0.5rem;
    padding: 0 2rem;
    width: 90%;
    height: 100%;
    color: #c5c5c5d2;
    text-decoration: none;
}

@media (max-width:480px) {
    .ToolPage-SearchBarContainer {
        width: 100%;
    }
    .ToolPage-SearchBar {
        width: 100%;
    }
    .ToolPage-Search-CancelButton {
        margin-left: 2.6rem;
        background-color: ivory;
        font-size: 1.7rem;
    }
}