.blinkers {
    max-height: 4rem;
}

.loadingScreenBG {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: #c9cbce8c;
}

.loadingScreenContainerMain {
    display: flex;
    transition: 1s;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.loadingScreenText {
    color: ivory;
    font-size: 1.5rem;
    padding: 0 0 0 1rem;
    font-weight: bolder;
    font-family: VarelaRound;
    text-shadow: 1px 1px 2px rgb(76, 76, 88);
}

.loadingScreenText span {
    top: 0.8rem;
    position: relative;
    display: inline-block;
    animation: bounce 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) infinite alternate;
    -webkit-animation: bounce 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) infinite alternate;
}

@media (max-width: 280px) {
    .loadingScreenContainerMain {
        flex-direction: column;
    }

    .loadingScreenText {
        padding: 0rem;
    }
}

@keyframes bounce {
    0% {
        top: 0.8rem;
    }

    100% {
        top: -0.8rem;
    }
}

@-webkit-keyframes bounce {
    0% {
        top: 0.8rem;
    }

    100% {
        top: -0.8rem;
    }
}

.loadingScreenText span:nth-child(2) {
    animation-delay: 0.1s;
}

.loadingScreenText span:nth-child(3) {
    animation-delay: 0.2s;
}

.loadingScreenText span:nth-child(4) {
    animation-delay: 0.3s;
}

.loadingScreenText span:nth-child(5) {
    animation-delay: 0.4s;
}

.loadingScreenText span:nth-child(6) {
    animation-delay: 0.5s;
}

.loadingScreenText span:nth-child(7) {
    animation-delay: 0.6s;
}

.loadingScreenText span:nth-child(8) {
    animation-delay: 0.7s;
}

.loadingScreenText span:nth-child(9) {
    animation-delay: 0.8s;
}

.loadingScreenText span:nth-child(10) {
    animation-delay: 0.9s;
}