.LoginPage {
    display: flex;
    min-height: 98.1vh;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.LoginContainer1 {
    border: solid;
    padding: 1rem 3rem;
    border-width: 0.1rem;
    border-radius: 1.5rem;
    border-color: #24354ba8;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.LoginContainer2 {
    display: flex;
    padding: 0.5rem 0;
    flex-direction: column;
    justify-content: center;
}

.Logintxt {
    padding: 0 0.5rem;
    font-size: 1.5rem;
    font-family: Viga;
}

.LoginInput {
    width: 25rem;
    margin: 0.5rem;
    padding: 0 2rem;
    font-size: 1.5rem;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    text-shadow: 2em 2em 3em -3em rgba(0, 0, 0, .5),
        1em 2em 3.5em -2.5em rgba(0, 0, 0, .5);
}

.loginSubmit {
    cursor: pointer;
    font-size: 1.3rem;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    padding: 0.4rem 0.5rem;
    font-family: Righteous;
}

.loginSubmit:active {
    transform: scale(0.95);
}

.loginLogo {
    height: 20rem;
}

.LoginContainer3 {
    gap: 3rem;
    display: flex;
    padding-right: 5rem;
    flex-direction: row;
}

.loginCreateUser {
    float: right;
    font-size: 1.1rem;
}

.shpassword {
    display: flex;
    cursor: pointer;
    font-size: 1.4rem;
    margin-left: -40px;
    justify-content: center;
}

.passwordContainer {
    display: flex;
    align-items: center;
}

.LogintxtContainer {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}

.LoginErrortxt {
    color: rgb(182, 53, 53);
}

.InvalidTxt {
    font-size: 1.2rem;
    font-family: Viga;
    text-align: right;
    color: rgb(182, 53, 53);
}

@media (max-width: 1150px) {
    .LoginPage {
        gap: 1rem;
        flex-direction: column;
    }

    .vLine {
        visibility: hidden;
    }

    .LoginContainer3 {
        gap: 0rem;
        padding-right: 0rem;
    }

    .loginLogo {
        height: 15rem;
    }
}

@media (max-width: 700px) {
    .loginLogo {
        height: 12rem;
    }

    .Logintxt {
        font-size: 1.1rem;
        padding: 0 0.1rem;
        font-family: Viga;
    }

    .LoginInput {
        width: 25rem;
        margin: 0.1rem;
        padding: 0 2rem;
        font-size: 1.3rem;
        text-shadow: 2em 2em 3em -3em rgba(0, 0, 0, .5),
            1em 2em 3.5em -2.5em rgba(0, 0, 0, .5);
    }

    .loginSubmit {
        font-family: Righteous;
        font-size: 1.2rem;
        border-radius: 0.5rem;
        padding: 0.4rem 0.5rem;
    }

    .loginCreateUser {
        text-align: right;
        font-size: 1rem;
    }

    .LoginInput {
        width: 20rem;
    }
}

@media (max-width: 500px) {
    .LoginInput {
        width: 15rem;
    }

    .LogintxtContainer {
        display: inline;
        flex-direction: column;
    }

    .LoginErrortxt {
        text-align: right;
    }
}

@media (max-width: 400px) {
    .LoginContainer1 {
        width: fit-content;
        padding: 1rem 1rem;
    }
}