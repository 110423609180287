.DeletePrompt-MainContainer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #c9cbce8c;
}

.DeletePrompt-Container {
    /* gap: 0.5rem; */
    display: flex;
    padding: 0.8rem;
    margin: 0rem 1rem;
    align-items: center;
    border-radius: 0.8rem;
    flex-direction: column;
    background-color: #cbd6d6;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.DeletePrompt-Text {
    text-align: center;
    font-family: VarelaRound;
}

.DeletePrompt-ButtonContainer {
    gap: 1rem;
    display: flex;
}

.DeletePrompt-Button {
    cursor: pointer;
    padding: 0 1rem;
    font-size: 1.05rem;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    font-family: Itim;
}