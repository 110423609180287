.UserProfile {
    min-height: 98.1vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.UserProfile-Container1 {
    border: solid;
    padding: 1rem;
    display: flex;
    border-width: 0.1rem;
    border-radius: 0.8rem;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.UserProfile-Container2 {
    gap: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.UserProfile-SaveButton {
    -moz-user-select: none;
    /* firefox */
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE*/
    user-select: none;
    /* Standard syntax */
    border: solid;
    cursor: pointer;
    font-size: 1.2rem;
    display: block;
    padding: 0.3rem 1rem;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    font-family: VarelaRound;
    background-color: rgba(240, 248, 255, 0.671);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.UserProfile-SaveButton:active {
    transform: scale(0.95);
}

.UserProfile-PWDContainer1 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.UserProfile-PWDContainer2 {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.UserProfile-PWDContainer3 {
    padding-right: 2rem;
}

@media (max-width: 400px) {
    .UserProfile-SaveButton {
        font-size: 1rem;
    }

    .UserProfile-Container1 {
        gap: 0.5rem;
    }

    .UserProfile-Container2 {
        gap: 0.2rem;
    }

    .UserProfile-PWDContainer3 {
        padding-right: 1.3rem;
    }
}

@media (max-width: 400px) {
    .UserProfile-SaveButton {
        font-size: 0.8rem;
        padding: 0.8rem 0.2rem;
    }
}