.WarningPage
{
    min-height: 97.7vh;;
    display: flex;
    align-items: safe center;
    justify-content: center;
}
.warningpage-bodyContainer
{
    gap: 1rem;
    width: 100%;
    height: auto;
    display: flex;
    padding: 1rem;
    border-radius: 0.8rem;
    flex-direction: column;
    background-color: #563B1C;
    background-image: url(../../pages/img/cardboard-flat.png);
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}
.warningpage-textContainer
{
    gap: 1.5rem;
    width: 100%;
    height: auto;
    display: flex;
    font-family: VarelaRound;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.warningpage-text 
{
    font-size: 1.3rem;
    color: rgb(150, 252, 218);
}
.warningpage-textBold
{
    font-size: 1.5rem;
    font-weight: bolder;
    color: rgb(252, 150, 150);
}