.TablePage {
    width: 100%;
    display: flex;
    min-height: 98.1vh;
}
.TablePage-MainContainer {
    gap: 0.5rem;
    width: 100%;
    display: flex;
    overflow: visible;
    margin: 4rem 2rem;
    flex-direction: column;
}
.TablePage-TitleContainer {
    gap: 1.5rem;
    display: flex;
}
.TablePage-TitleContainer2 {
    gap:0.5rem;
    display:flex;
    border-bottom: solid;
    border-width: 0.1rem;
    padding-bottom: 1rem;
    border-radius: 0.2rem;
    flex-direction: column;
}
.TablePage-TableImageContainer {
    display: flex;
    width: 11rem;
    height: auto;
    flex-direction: row;
}
.TablePage-TableImage {
    width: 11rem;
    object-fit: contain;
    border-radius: 0.8rem;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.TablePage-TableImageUpload {
    font-size: 1.5rem;
    margin-left: -3.2rem;
    margin-top: 0.5rem;
    height: min-content;
    flex-direction: row;
    display: flex;
    border-width: 0.1rem;
    border-radius: 0.3rem;
    overflow: hidden;
    background-color: rgba(240, 248, 255, 0.808);
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.TablePage-TableImageUpload:active {
    transform: scale(0.95);
}
.TablePage-TableImageUpload1 {
    display: flex;
    padding: 0.5rem;
    border: solid;
    border-width: 0.1rem;
    border-radius: 0.5rem;
}
.TablePage-TableImageUpload2 {
    visibility: hidden;
    width: 0rem;
}
.TablePage-DescptContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem;
}
.TablePage-TableNameContainer {
    gap:2rem;
    height: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: VarelaRound;
    display: flex;
    align-items: center;
}
.TablePage-EditTableBtnContainer {
    display: flex;
    gap: 0.5rem;
    box-shadow: 2em 2em 4em -3em rgba(0,0,0,.5), 1em 2em 3.5em -2em rgba(0,0,0,.5);
}
.TablePage-EditTableBtn {
    display: flex;
    border: solid;
    cursor: pointer;
    font-size: 2rem;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.TablePage-EditTableBtn:active {
    transform: scale(0.95);
}
.TablePage-TableDescptContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: VarelaRound;
}
.TablePage-TableDescpt {
    height: 100%;
    display: flex;
    font-size: 1.1rem;
    font-family: Itim;
    font-weight: normal;
    text-align: justify;
    white-space: pre-wrap;
}
.TablePage-EditTableNameInput {
    border: none;
    font-size: 1.5rem;
    padding: 0 0.5rem;
    border-bottom: solid;
    border-width: 0.1rem;
    border-radius: 0.5rem;
}
.TablePage-TableDescptInput {
    resize: none;
    height: 100%;
    display: flex;
    padding: 0 0.5rem;
    font-size: 1.01rem;
    border-radius: 0.5rem;
}
.TablePage-ColumnsContainer {
    display: flex;
    flex-direction: column;
    transition: 4s;
}
.TablePage-AddColumnsBtn {
    border: solid;
    display: flex;
    font-size: 1.3rem;
    border-radius: 0.5rem;
    border-width: 0.1rem;
    justify-content: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.TablePage-AddColumnsBtn:active {
    transform: scale(0.99);
    transform: scaleY(0.9);
    transition: 0.5s;
}

.TablePage-TableColumns {
    display: flex;
    gap:1rem;
    margin: 0.5rem 0;
    flex-wrap: wrap;
}
.TablePage-addColumnsContainer {
    display: flex;
}
.TablePage-Columnslbl {
    border-bottom: solid;
    border-width: 0.1rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    text-transform: capitalize;
    font-size: 1.1rem;
    background-color: rgba(223, 230, 241, 0.76);
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.TablePage-ColumnsInput {
    border-radius: 0.5rem;
    border-width: 0.1rem;
    font-size: 1.1rem;
    padding: 0.5rem 1rem;
    text-align: justify;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.TablePage-removeColumnsBtn {
    display: flex;
    font-size: 2rem;
    margin-left: -3rem;
    padding: 0rem 0.5rem;
    align-items: center;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    color: rgb(134, 18, 47);
    background-color: #F4FFF8;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.TablePage-removeColumnsBtn:active {
    transform: scale(0.95);
}

.TablePage-DataListMainContainer{
    height: 100%;
    overflow: hidden;
    border-radius: 0.3rem 0.3rem 1rem 1rem;
}
.TablePage-DataListContainer{
    height: 100%;
    border-top: solid;
    margin-top: -0.2rem;
    border-radius: 0.3rem;
    border-color: #24354B;
    background: rgba(252, 252, 252, 0.678);
}
.TablePage-DataPointImageDisplay {
    width: 3rem;
    border-radius: 0.5rem;
}
.TablePage-DataPointNameDisplay {
    cursor: pointer;
    font-size: 1.1rem;
    border-left: solid;
    padding-left: 0.5rem;
    font-family: VarelaRound;
}
.TablePage-DataPointNameSN {
    cursor: pointer;
    min-width: 3rem;
    text-align: right;
    /* border-left: solid;
    border-right: solid;
    border-width: 0.1rem;
    padding-right: 0.5rem;
    border-radius: 0.5rem; */
}

.TablePage-DataMainContainer-Individual {
    display: flex;
    flex-direction: row;
    border-radius: 0.3rem;
    border-bottom: solid;
    border-width: 0.1rem;
    overflow: hidden;
    background-color: #FCFCFC;
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
}
.TablePage-DataPointMainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.TablePage-CreateDataPointMainContainer {
    width: 100%;
    display: flex;
    /* border-top: solid; */
    border-bottom: solid;
    border-width: 0.1rem;
    /* border-radius: 0.5rem; */
    padding: 1rem 0rem;
    flex-direction: column;
}
.TablePage-DataPointContainer {
    gap: 1rem;
    display: flex;
    flex-direction: row;
    padding: 1rem 0.5rem;
}
.TablePage-Data-ArrowSymb {
    display: flex;
    width: 2rem;
    justify-content: center;
    background-color: #FCFCFC;
}
.TablePage-DataImageContainer {
    display: flex;
    width: min-content;
    flex-direction: row;
}
.TablePage-DataImageUpload {
    display: flex;
}
.TablePage-DataImageUpload1 {
    display: flex;
    border: solid;
    padding: 0.3rem;
    font-size: 1.3rem;
    height: min-content;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    margin: 0.5rem 0rem 0rem -2.5rem;
    background-color: rgba(240, 248, 255, 0.808);
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.TablePage-DataImageUpload1:active {
    transform: scale(0.95);
}
.TablePage-DataPointImage {
    width: 7rem;
    object-fit: contain;
    border-radius: 0.8rem;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.TablePage-DataPointNameInput {
    gap: 0.5rem;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
}
.TablePage-DataPointNamelbl {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-family: Viga;
}
.TablePage-DataPointDescptlbl {
    display: flex;
    font-size: 1rem;
    font-family: Viga;
    align-items: center;
}
.TablePage-DataPointName{
    width: 100%;
    display: flex;
    font-size: 1.2rem;
    align-items: center;
    padding-left: 0.5rem;
}
.TablePage-addDataPointNameInput {
    width: 100%;
    border: none;
    font-size: 1.1rem;
    border-bottom: solid;
    border-width: 0.1rem;
    padding-left: 0.5rem;
    border-radius: 0.5rem;
}
.TablePage-DataPointNameContainer1 {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.TablePage-DataPointNameContainer2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.TabePage-DataPointNameContainer3 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.TablePage-DataGrpBtnContainer {
    gap: 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.TablePage-DataPointDescptContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.TablePage-DataPointDescptInput {
    width: 100%;
    height: 100%;
    resize: none;
    padding: 0 0.3rem;
    border-radius: 0.5rem;
}
.TablePage-DataPointDescpt {
    height: 100%;
    border-top: solid;
    border-bottom: solid;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    white-space: pre-wrap;
}
.TablePage-DataPointColumnsContainer{
    gap: 1rem;
    display: flex;
    padding: 0 0.5rem;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 0.5rem;
}
.TablePage-TableDataMainContainer {
    gap: 0.5rem;
    display: flex;
    margin-left: 0.5rem;
    flex-direction: row;
    flex-wrap: wrap;
}
.TablePage-DataContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    border-bottom: solid;
    border-width: 0.1rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: rgba(238, 244, 248, 0.747);
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}
.TablePage-DataNamelbl {
    font-size: 1.1rem;
    font-family: Viga;
    margin-right: 0.8rem;
}
.TablePage-DataDisplay {
    font-size: 1.05rem;
    /* margin-left: 0.8rem; */
    font-family: VarelaRound;
}
.TablePage-DataInput {
    /* margin-left: 1rem; */
    font-size: 1.05rem;
    border: none;
    border-bottom: solid;
    border-width: 0.1rem;
    border-radius: 0.5rem;
    padding: 0.1rem 0.5rem;
}
.TablePage-DropDownList{
    font-size: 1.1rem;
    flex-direction: row;
    margin-left: 13.2rem;
    font-family: VarelaRound;
    text-transform: capitalize;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
}

@media (max-width: 650px) {
    .TablePage-TitleContainer {
        gap: 0.5rem;
        flex-direction: column;
        align-items: center;
    }
    .TablePage-TableNameContainer {
        gap: 0;
        justify-content: space-between;
        padding-bottom: 1rem;
    }
    .TablePage-TableDescptInput {
        min-height: 7rem;
    }
    .TablePage-TableDescpt {
        min-height: 2rem;
    }
}
@media (max-width: 500px) {
    .TablePage-TableNameContainer {
        flex-direction: column-reverse;
        gap: 0.5rem;
    }
    .TablePage-TableNametxt {
        width: 100%
    }
    .TablePage-EditTableNameInput {
        width: 100%;
    }
    .TablePage-DataPointContainer {
        gap: 0.5rem;
        padding: 0 0.5rem;
        flex-direction: column;
        align-items: center;
    }
    .TablePage-DataPointNameContainer2 {
        flex-direction: column-reverse;
        align-items: center;
        gap:0.3rem;
    }
    .TablePage-DataPointNameInput {
        width: 100%;
    }
    .TablePage-DataPointDescpt {
        min-height: 4rem;
        margin-bottom: 1rem;
    }
    .TablePage-DataPointDescptInput {
        height: 4rem;
    }
}
@media (max-width: 480px) {
    .TablePage-DropDownList{
        margin-left: 0rem;
        border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
    }
}