@font-face {
    font-family: "VarelaRound";
    src: local("VarelaRound-Regular"), url(../fontAsset/VarelaRound-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Itim";
    src: local("Itim-Regular"), url(../fontAsset/Itim-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Righteous";
    src: local("Righteous-Regular"), url(../fontAsset/Righteous-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Viga";
    src: local("Viga-Regular"), url(../fontAsset/Viga-Regular.ttf) format("truetype");
}